import { Controller } from '@hotwired/stimulus';
import moment from 'moment-timezone';

export default class ScrollController extends Controller {
  static targets = ['container'];

  declare readonly containerTarget: HTMLElement;
  declare readonly hasContainerTarget: boolean;

  connect() {
    this.scrollToCurrentTime();
  }

  scrollToCurrentTime() {
    const startDateTime = moment.tz(
      this.containerTarget.dataset.startDateTime!,
      this.containerTarget.dataset.timezone!,
    );
    const currentDateTime = moment.tz(
      this.containerTarget.dataset.currentDateTime!,
      this.containerTarget.dataset.timezone!,
    );

    if (
      startDateTime.year() === currentDateTime.year() &&
      startDateTime.month() === currentDateTime.month() &&
      startDateTime.date() === currentDateTime.date()
    ) {
      const scrollToHour = currentDateTime.hours() - 1;
      const scrollPosition =
        Math.max(scrollToHour - 7, 0) * (document.querySelector('.w-hour') as HTMLElement).offsetWidth;
      this.containerTarget.scrollLeft = scrollPosition;
    }
  }
}
