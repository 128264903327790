import { Controller } from '@hotwired/stimulus';

export default class Dialog extends Controller {
  static targets = ['dialog'];

  declare readonly dialogTarget: HTMLDialogElement;

  open() {
    this.dialogTarget.showModal();
  }

  close() {
    this.dialogTarget.close();
  }
}
