import { Controller } from '@hotwired/stimulus';

export default class PackageStatesController extends Controller {
  static targets = ['state'];

  declare stateTargets: HTMLDivElement[];

  connect() {
    const isBelowMd = window.matchMedia('(max-width: 768px)').matches;
    let dashboardPackageStates: string[];

    if (isBelowMd) {
      const urlParams = new URLSearchParams(window.location.search);
      const stateParam = urlParams.get('state');
      const stateToDashboardPackageStates: { [key: string]: string[] } = {
        inbound_scan: ['inbound_scan'],
        sort_scan: ['inbound_scan', 'sort_scan'],
        despatched: ['sort_scan', 'despatched'],
        hold: ['hold'],
      };

      dashboardPackageStates = stateToDashboardPackageStates[stateParam ?? ''] || [];
    } else {
      dashboardPackageStates = ['inbound_scan', 'sort_scan', 'despatched', 'hold'];
    }

    this.stateTargets.forEach((element) => {
      const { state } = element.dataset;

      if (state && dashboardPackageStates.includes(state)) {
        element.classList.remove('hidden');
        element.parentElement?.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
        element.parentElement?.classList.add('hidden');
      }
    });
  }
}
