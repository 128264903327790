import { Controller } from '@hotwired/stimulus';
import moment from 'moment-timezone';

export default class TimelineIndicatorController extends Controller {
  static targets = ['container', 'timelineIndicator'];

  declare readonly containerTarget: HTMLElement;
  declare timelineIndicatorTargets: HTMLElement[];

  connect() {
    this.moveTimeIndicator();
  }

  moveTimeIndicator() {
    if (this.getCurrentHours() > 7 && this.getCurrentHours() < 22) {
      setInterval(this.setNewPosition.bind(this), 1000);
    }
  }

  setNewPosition() {
    this.timelineIndicatorTargets.forEach((target) => {
      const indicator = target;
      const hours = this.getCurrentHours();
      const minutes = this.getCurrentMinutes();
      const newPosition = ((hours - 7) * 60 + minutes) * 0.6;
      indicator.style.left = `${newPosition}rem`;
    });
  }

  private getLocalCurrentDate() {
    return moment.tz(this.containerTarget.dataset.currentDateTime!, this.containerTarget.dataset.timezone!);
  }

  private getCurrentHours() {
    return this.getLocalCurrentDate().hours();
  }

  private getCurrentMinutes() {
    return this.getLocalCurrentDate().minutes();
  }
}
