import { Controller } from '@hotwired/stimulus';

export default class MobileWmsMenuController extends Controller {
  static targets = ['dropdownMenu', 'title'];

  declare readonly dropdownMenuTarget: HTMLUListElement;
  declare readonly titleTarget: HTMLSpanElement;

  connect(): void {
    document.addEventListener('click', this.handleOutsideClick);
  }

  disconnect(): void {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  toggleDropdown() {
    this.dropdownMenuTarget.classList.toggle('hidden');
  }

  handleOutsideClick = (event: MouseEvent) => {
    if (!this.element.contains(event.target as Node) && !this.dropdownMenuTarget.classList.contains('hidden')) {
      this.dropdownMenuTarget.classList.add('hidden');
    }
  };
}
